


































import { Component, Vue } from "vue-property-decorator";
@Component({
	filters: {
		statusName(status: number) {
			const statusMap = ["待审核", "处理中", "已开放"];
			return statusMap[status];
		}
	}
})
export default class Header extends Vue {
	private tableData = [
		{
			name: "智慧消防云服务平台",
			time: "2020/11/05 12:00:00",
			status: 0
		},
		{
			name: "智慧消防云服务平台",
			time: "2020/11/05 12:00:00",
			status: 1
		},
		{
			name: "智慧消防云服务平台",
			time: "2020/11/05 12:00:00",
			status: 2
		}
	];
}
